import {
  reactive,
  computed,
} from '@vue/composition-api'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const authId = computed(() => localStorage.getItem('authId'))

const user = reactive({
  id: '',
  name: '',
  avatar: '',
  country: '',
  city: '',
  role: '',
  plan: '',
  stripe_id: '',
})

export default function useUserSingle() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  // const loading = ref(false)
  const { http } = useApollo()

  async function getUser() {
    // local storage causes issues when updates happen
    // use in the future and flush storage on update
    // const userSingle = JSON.parse(localStorage.getItem('user'))
    // if (userSingle) {
    //   console.log('already have user in local')
    //   user.value.profile = userSingle
    //   return
    // }

    await http.value.query({
      query: gql`
        query getUserSingle {
          ${frontSchema}_users_by_pk(id: "${authId.value}") {
            avatar
            created_at
            id
            last_seen
            name
            plan
            role
            stripe_id
            country
            city
          }
        }
      `,
    })
      .then(result => {
        const u = result.data[`${frontSchema}_users_by_pk`]
        Object.assign(user, u)
        // set default avatar
        if (!user.avatar) user.value.avatar = 'v1649821754/user/default/default_avatar_nmr1uu.png'
        // store user in localStorage
        localStorage.setItem('user', JSON.stringify(user))
      })
      .catch(err => console.error('getUser', err))
    // return user
  }

  // async function getUserFull() {
  //   console.log('firingGetUser', authId.value)
  //   await apolloClient.http.value.query({
  //     query: gql`
  //       query getUserSingle {
  //         ${frontSchema}_users_by_pk(id: "${authId.value}") {
  //           avatar
  //           createdAt
  //           id
  //           lastSeen
  //           name
  //           plan
  //           role
  //           stripe_id
  //           country
  //           city
  //         }
  //       }
  //     `,
  //   })
  //     .then(result => {
  //       console.log('userProfile', result.data[`${frontSchema}_users_by_pk`])
  //       Object.keys(result.data[`${frontSchema}_users_by_pk`]).forEach(key => {
  //         user.profile[key] = result.data[`${frontSchema}_users_by_pk`][key]
  //       })
  //     })
  //     .catch(err => console.log(err))
  //   // return user
  // }

  async function updateUserProfile() {
    const profile = user.value
    await http.value.mutate({
      mutation: gql`
        mutation updateUserProfile {
          update_${frontSchema}_users_by_pk(
            pk_columns: {id: "${authId.value}"},
            _set: {
              name: "${profile.name}"
              country: "${profile.country}",
              city: "${profile.city}",
              avatar: "${profile.avatar}",
            })
          {
            country
            name
            avatar
            city
          }
        }
      `,
    })
      .then(result => {
        const u = result.data[`update_${frontSchema}_users_by_pk`]
        localStorage.setItem('user', JSON.stringify(u))
        Object.keys(u).forEach(key => {
          if (typeof key !== 'object' || key === null || key === undefined) {
            user.value[key] = u[key]
          }
        })
      })
      .catch(err => console.error('updateUserProfile', err))
  }

  return {
    user: computed(() => user),
    getUser,
    updateUserProfile,
  }
}
