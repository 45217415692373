import {
  ref,
  readonly,
  computed,
} from '@vue/composition-api'
// import instruments from '@/data/instruments.json'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import { useGtm } from '@gtm-support/vue2-gtm'
import useUserSingle from '../users/useUserSingle'

const token = localStorage.getItem('authToken')

const customer = ref({
  session: {},
})

export default function useStripe() {
  // const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { user } = useUserSingle()
  const gtm = useGtm()

  async function getStripeSession(sId) {
    const options = {
      method: 'POST',
      url: `${process.env.VUE_APP_MLFX_API_BASE}/stripe/session`,
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      data: {
        sessionId: sId,
        secondThing: 'test2',
      },
    }
    axios.request(options)
      .then(res => {
        const s = res.data
        customer.value.session = {
          id: s.customer,
          paymentStatus: s.payment_status,
        }
        gtm.trackEvent({
          event: 'stripe-purchase', // Event type [default = 'interaction'] (Optional)
          category: 'purchase',
          action: 'click',
          label: 'Stripe Checkout Session',
          value: s.amount_total,
          status: s.payment_status,
          // noninteraction: false, // Optional
        })
      })
      .catch(err => {
        console.error('getStripeSession', err)
      })
  }

  async function stripeCheckout({ price }) {
    const stripe = await loadStripe(String(process.env.VUE_APP_STRIPE_SECRET))

    if (user.value) {
      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_MLFX_API_BASE}/stripe/checkout`,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        data: {
          customerId: user.value.stripe_id,
          priceId: price,
        },
      }
      const session = await axios.request(options)
      const checkout = await stripe.redirectToCheckout({
        sessionId: session.data.id,
      })
      if (checkout.error) {
        throw new Error(`stripe checkout error ${checkout.error}`)
      }
    } else {
      // user is already gold, provide feedback
    }
  }

  async function stripeCustomerPortal() {
    const options = {
      method: 'POST',
      url: `${process.env.VUE_APP_MLFX_API_BASE}/stripe/customer-portal`,
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      data: {
        stripe_id: user.value.stripe_id,
      },
    }
    axios.request(options)
      .then(res => {
        window.location.href = res.data
      })
      .catch(err => {
        console.error('stripeCustomerPortal', err)
      })
  }

  return {
    customerSession: computed(() => readonly(customer.value.session)),
    redirectStripeCheckout: stripeCheckout,
    stripeCustomerPortal,
    getStripeSession,
  }
}
